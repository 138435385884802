<template>
  <div class="buy-faster">
    <div class="buy-faster-title">{{ $t("ListingProcess.UpgradeTitle") }}</div>
    <div class="second-title">{{ $t("ListingProcess.Pay") }} {{fee||50}}$ {{ $t("ListingProcess.UpgradeMessage1") }}</div>
    <ul>
      <li>
        {{ $t("ListingProcess.UpgradeMessage2") }}
      </li>
      <li>
        {{ $t("ListingProcess.UpgradeMessage3") }}
      </li>
      <li>
        {{ $t("ListingProcess.UpgradeMessage4") }}
      </li>
    </ul>
    <div class="buy-faster-footer">
      <b-button @click="notChangePlan()"> {{ $t("ListingProcess.NoThankYou") }}</b-button>
      <b-button @click="changePlan()" class="yes">{{ $t("ListingProcess.YesIAmInterested") }}</b-button>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  props: {
    changed: { type: Function },
    fee: {type: Number}
  },
  methods: {
    scrollToTop(){
      const el = document.querySelector('.success-holder');
      if (el) el.scrollIntoView();
    },
    async changePlan() {
      try {
        await axios.post("Listing/SetListing", {
          ListingId: this.$store.getters.listingStockID,
          PlanId: this.$store.getters.listingPlanID
        });
        this.$store.dispatch("listingPlanID", 2);
        this.scrollToTop();
        this.changed();
      } catch (error) {
        console.log(error);
      }
    },
    notChangePlan() {
      this.scrollToTop();
      this.changed();
    },

  }
};
</script>
<style type="text/css" lang="scss">
  .buy-faster {
    padding: 40px;
    border-radius: 4px;
    background: white;
    margin-bottom: 64px;
    width: 850px;
    margin-right: auto;
    margin-left: auto;
    @media (max-width: 991px) {
      width: 100%;
    }
    &-title {
      background: $yellow;
      height: 48px;
      border-radius: 40px;
      font-size: 24px;
      font-family: "poppins", sans-serif;
      font-weight: 500;
      margin-bottom: 24px;
      padding: 8px 24px;
      display: inline-flex;
      align-items: center;
    }
    .second-title {
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 24px;
    }
    ul {
      padding: 0;
      list-style: none;
      li {
        font-size: 16px;
        font-family: "Inter", sans-serif !important;
        line-height: 24px;
        color: #363636;
        position: relative;
        padding-left: 30px;
        &:before {
          content: "\f00c";
          position: absolute;
          left: 0;
          top: 0;
          font-family: "FontAwesome";
          color: $light-blue;
          font-size: 20px;
        }
        &:not(:last-child) {
          margin-bottom: 32px;
        }
      }
    }
    &-footer {
      text-align: right;
      margin-top: 32px;
      button {
        border-radius: 40px;
        height: 56px;
        color: $light-blue;
        font-size: 20px;
        font-weight: 600;
        font-family: "Inter", sans-serif !important;
        background: none;
        border: none;
        padding: 0 20px !important;
        transition: all 0.3s;
        &.yes {
          background: $light-blue;
          color: white;
          margin-left: 16px;
        }
        &:hover {
          background: #00559d !important;
          color: white !important;
        }
      }
    }
  }
</style>